import $, { error } from "jquery";
import Flickity from 'flickity';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/all";
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import { horizontalLoop } from "./utils";

// LazySizes config
Object.assign(lazySizes.cfg, {
    preloadAfterLoad: true,
});

// APIs
const API_URL_PLEDGE_COUNT = 'https://apim-dig-prod-awiservices.azure-api.net/marketing-campaign/?campaignID=7017T000000HvZeQAK';
const API_URL_PLEDGE_FORM = 'https://info.woolmark.com/l/982232/2023-08-27/62nt5';
const API_URL_GET_COUNTRY_STATE = "/api/country/GeoCountryState";        
const MIN_PLEDGES_COUNT = 20000;

// Main script
const common = (($) => {  
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    /**
     * Fire events on document ready and bind other events
     */
    const ready = () => {

        //---------------------------
        // Generic Scrolltrigger animation classes
        //---------------------------
        gsap.utils.toArray('[data-animated]').forEach((item) => {
            const start =
            $(item).data('animated-start') !== undefined
                ? $(item).data('animated-start')
                : '250px bottom'
            ScrollTrigger.create({
                trigger: item,
                once: true,
                start: start,
                onEnter: () => {
                    item.classList.add('active')
                }
            })
        })



        //---------------------------
        // BLOCK - Persistent Nav (block #1)
        //---------------------------

        if ($('#persistentNav').length) {

            let livePledgeCount = 0;

            // Fetch API data
            fetch(API_URL_PLEDGE_COUNT, {
                method: 'GET',
            }).then((response) => {
                return response.json();
            }).then((_json) => {
                showNavWithCount(_json);
            }).catch((_error) => {
                console.log('error', _error)
                showNav();
            });
 
            // scroll to pledge block
            if ($('#pledgeAnchor').length) {
                $('.persistentNav-pledgeAnchor').on('click', () => {
                    gsap.to(window, {duration: 1, scrollTo:{y:"#pledgeAnchor", offsetY: 140}});
                });
            }

            function showNav() {
                $('#persistentNav').addClass('show')
            }

            function showNavWithCount(json) {
                // if pledges count is over 20,000 then show the count
                if (json.count && json.count > MIN_PLEDGES_COUNT) {
                    $('#persistentNav').addClass('showNavWithCount')
                    livePledgeCount = json.count - json.recent_submissions.length;

                    // counting up animation
                    gsap.to($('#pledgeCount'), {
                        innerText: livePledgeCount, 
                        duration: 3, 
                        delay: 0.5,
                        snap: {
                            innerText:1
                        }  ,
                        onComplete: () => {
                            // call pledge name animations after counting is finished
                            if (json.recent_submissions.length) {
                                updateText(0, json.recent_submissions)
                            }
                        }  
                    });
                } else {
                    showNav();
                }
            }
            
            // loop over pledge names until the array has been counted
            function updateText(counter, nameArray) {
                // if state doesn't exist use contry as a backup
                const pledgeLocation = nameArray[counter].state ? nameArray[counter].state : nameArray[counter].country;
                $('#pledgeName').html("SOMEONE FROM " + pledgeLocation);

                let timeline = gsap.timeline({defaults: {ease: 'power3.inOut',duration: 1, }});

                timeline.to(
                    ".persistentNav-leftColumn-inner",
                    {
                        yPercent: -150,
                        delay: 3 + (Math.random() * 3),
                    }
                );
                timeline.call(() => {
                    livePledgeCount ++
                    $('#pledgeCount').html(livePledgeCount)
                });
                timeline.to(
                    ".persistentNav-leftColumn-inner",
                    {
                        yPercent: 0,
                        delay: 1
                    }
                );
                timeline.call(() => {
                    if (counter < nameArray.length - 1) {
                        counter++;
                        updateText(counter, nameArray);
                    }
                });
            }

            // scrolltrigger to hide nav when over the pledge block 
            if ($('#pledgeAnchor').length) {
                ScrollTrigger.create({
                    trigger: '#pledgeAnchor',
                    start: 'top center',
                    end: 'bottom center',
                    toggleClass: {
                        targets: '#persistentNav',
                        className: "hidden"
                    }
                })    

                ScrollTrigger.create({
                    trigger: "#heroVideo",
                    start: 'top center',
                    end: 'bottom center',
                    toggleClass: {
                        targets: '#persistentNav',
                        className: "hidden"
                    }
                })
            }
        }


        //---------------------------
        // Masthead Video (#2)
        //---------------------------
        $('[data-lazy-video]').each(function (index, el) {
            const videoVideo = el;
            const videoSource = el.querySelector(".video-source");
            const videoSourceSrc = videoSource.getAttribute('data-src');
            if (videoSource) {
                setTimeout(() => {
                videoSource.setAttribute('src', videoSourceSrc);
                videoVideo.load();
                if (!videoVideo.classList.contains('paused')) {
                    videoVideo.play();
                }
                videoVideo.classList.add('loaded')
                }, 1000);
            }
        });



        //---------------------------
        // BLOCK - Sign the Pledge (#3)
        //---------------------------
        if ($('#pledgeAnchor').length) {

            prepopulateCountry();
            const contentSubmitted = document.getElementById('content_submitted')
            const newPledgeBtn = document.getElementById('new_pledge_btn')
            const filterCitizenBtn = document.getElementById('filter_citizen_btn')
            const filterBrandBtn = document.getElementById('filter_brand_btn')
            const contentCitizen = document.getElementById('content_citizen')
            const contentBrand = document.getElementById('content_brand')

            function prepopulateCountry() {
                fetch(API_URL_GET_COUNTRY_STATE , {
                    method: 'GET',
                }).then((response) => {
                    return response.json();
                }).then((_json) => {
                    $("input[id=Country]").each((_, obj) => $(obj).val(_json.CountryName))
                    $("input[id=CountryCode]").each((_, obj) => $(obj).val(_json.CountryCode))
                    $("input[id=State]").each((_, obj) => $(obj).val(_json.StateCode))
                }).catch((_error) => {
                    console.log('error', _error)
                });                
            }

            // Change session tab
            const changeSessionTab = (tab) => {
                if (filterCitizenBtn) {
                    filterCitizenBtn.classList.remove('filter-active')
                }
                if (filterBrandBtn) {
                    filterBrandBtn.classList.remove('filter-active')
                }
                if (contentCitizen) {
                    contentCitizen.classList.remove('is-active')
                }
                if (contentBrand) {
                    contentBrand.classList.remove('is-active')
                }
                if (contentSubmitted) {
                    contentSubmitted.classList.remove('is-active')
                }
                if (tab === 'citizen') {
                    if (filterCitizenBtn) {
                        filterCitizenBtn.classList.add('filter-active')
                    }
                    if (contentCitizen) {
                        contentCitizen.classList.add('is-active')
                    }
                } else if (tab === 'brand') {
                    if (filterBrandBtn) {      
                        filterBrandBtn.classList.add('filter-active')
                    }
                    if (contentBrand) {
                        contentBrand.classList.add('is-active')
                    }
                } else if (tab === 'submitted') {
                    if (contentSubmitted) {
                        contentSubmitted.classList.add('is-active')
                    }
                }
            }

            // Citizen form tab
            if (filterCitizenBtn) {
                filterCitizenBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    changeSessionTab('citizen')
                })
            }

            // Brand form tab
            if (filterBrandBtn) {
                filterBrandBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    changeSessionTab('brand')
                })
            }

            // New pledge button
            if (newPledgeBtn) {
                newPledgeBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    changeSessionTab('citizen')

                    // clear all input fields .. except hidden field as they are pre-populated
                    $('#pledgeAnchor input:not([type=hidden]):not([type=checkbox])').val('');
                })
            }

            const brandSubmtitBtn = $(`#brand_submit_btn`);
            const redirectAfterSubmitUrl = $("#pledgeAnchor").data("redirectAfterSubmit");

            brandSubmtitBtn.on('click', (event) => {
                event.preventDefault()
                window.appInsights && window.appInsights.trackEvent("EcoCampaignPledgeBrand");
                
                if (hasValidFields('#brand_form')) {
                    brandSubmtitBtn.addClass('button--loading')
                    submitForm('#brand_form')
                }
            });

            const citizenSubmtitBtn = $(`#citizen_submit_btn`);
            citizenSubmtitBtn.on('click', (event) => {
                event.preventDefault()
                window.appInsights && window.appInsights.trackEvent("EcoCampaignPledgeCitizen");

                if (hasValidFields('#citizen_form')) {
                    citizenSubmtitBtn.addClass('button--loading')
                    submitForm('#citizen_form')
                };
            });

            function hasValidFields(formID){
                let isValid = true;

                // check if text fields are valid 
                $('input[type="text"]:not(.honeypot)', formID).each(function(){
                    if(!$(this).val()){
                        isValid = false;
                        $(this).addClass("error");
                    } else{
                        $(this).removeClass("error");
                    }
                });
                
                // check if email fields are valid 
                $('input[type="email"]', formID).each(function(){
                    if(!isEmail($(this).val())){
                        isValid = false;
                        $(this).addClass("error");
                    } else{
                        $(this).removeClass("error");
                    }
                });

                function isEmail(email) {
                    let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                    if(!regex.test(email)) {
                        return false;
                    }else{
                        return true;
                    }
                }

                return isValid;
            }

            function submitForm(formID){
                event.preventDefault();

                // this to do full page reload instead of ajax
                // $(formID).attr("action", API_URL_PLEDGE_FORM);
                // $(formID).submit();
                // return;

                // Pardot submission using iframe to simulate ajax functionality as ajax submission is not supported in Pardot. no control over CORS header...
                // This is a workaround to simulate ajax submission, but error handling is not ideal - we detect on the error url to contains "errorMessage" querystring
                $('body').append('<iframe id="pardot-form-handler" name="pardot-form-handler" style="display:none" src="javascript:false;"></iframe>');
                $(formID).attr("action", API_URL_PLEDGE_FORM);
                $(formID).attr("target", "pardot-form-handler");

                const pardotIframe = document.getElementById("pardot-form-handler");
                pardotIframe.addEventListener("load", function() {
                    brandSubmtitBtn.removeClass('button--loading');
                    citizenSubmtitBtn.removeClass('button--loading');

                    //check error. If pardot throws error, it will redirect back with errorMessage on the querystring
                    const iframeCurrentUrl = pardotIframe.contentWindow.location.href;
                    let params = new URLSearchParams(iframeCurrentUrl);
                    const errorMessage = params.get("errorMessage");
                    if (errorMessage) {
                        console.log("pardot form submission error", errorMessage);

                        const err = new Error(`Pardot form submission error ${errorMessage}`)
                        window.appInsights && window.appInsights.trackException(`Pardot form submission error ${errorMessage}`);
                        window.appInsights.flush();
                        throw err;
                        return;
                    }

                    // success state
                    changeSessionTab('submitted');

                    if (redirectAfterSubmitUrl) {
                        setTimeout(() => {
                            window.location.href = redirectAfterSubmitUrl;
                        }, 2000);    
                    }
                });

                $(formID).submit();                
                return;

                let formdata = new FormData();
                $('input', formID).each(function(){
                    let name = $(this).attr("name");
                    let value = null;

                    if ($(this)[0].type == 'text' || $(this)[0].type == 'email' || $(this)[0].type == 'hidden') {
                        value = $(this).val()
                    } else if ($(this)[0].type == 'checkbox') {
                        value = $(this).is(':checked')
                    }

                    if (name !== null && value !== null) {
                        formdata.append(name, value);
                    }
                })

                let requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow',
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                };

                // Submit to API
                fetch(API_URL_PLEDGE_FORM, 
                    requestOptions
                ).then((response) => {
                    return response.text();
                }).then((_json) => {
                    brandSubmtitBtn.removeClass('button--loading')
                    citizenSubmtitBtn.removeClass('button--loading')
                    changeSessionTab('submitted')
                }).catch((_error) => {
                    console.warn('error', _error)
                });                
                                    
            }

            let pageUrl = encodeURIComponent(document.URL);
            const shareBtns = $(`.signThePledge-share-item`);

            shareBtns.on('click', (event) => {
                event.preventDefault()
                
                if ($(event.currentTarget).hasClass('facebook')) {
                    window.open("https://www.facebook.com/sharer.php?u=" + pageUrl);
                }
                else if ($(event.currentTarget).hasClass('linkedin')) {
                    window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl);
                }
            });

        }

        

        //---------------------------
        // BLOCK - Share Panels (#4)
        //---------------------------
        if ($('#shareAnchor').length) {

            // if url has share hash, scroll to and open the share panel
            const shareHashes = ['#synthetic-clothes-outlive-grandchildren', '#earth-needs-filter-by-fabric', '#fashion-can-change'];
            let urlHash = window.location.hash;
        
            if (shareHashes.includes(urlHash)) { 
                gsap.to(window, {
                    duration: 1, 
                    scrollTo:{y:"#shareAnchor", offsetY: 140},
                    onComplete: () => {
                        openSharePopup($('#sharePanelsContainer .panel').eq(shareHashes.indexOf(urlHash)))
                    }  
                });
            }
            
            // on panel clicked open the popup
            $(`.panel`).on('click', (event) => {
                openSharePopup($(event.currentTarget))
            })

            // close the popup if close button or background is clicked 
            $(`#sharePanels_popup`).on('click', (event) => {
                if ($(event.target).hasClass('sharePanels-popup-bg') || $(event.target).hasClass('sharePanels-popup-closeBtn')) {
                    $('#sharePanels_popup').removeClass('active')
                }
            });

            // copy share url to clipboard 
            $(`#sharePanels_copy`).on('click', (event) => {
                const copyUrl = $(event.target).data('url')
                if (copyUrl) {
                    navigator.clipboard.writeText(copyUrl);
                    $(`#sharePanels_copy`).html('copied!')
                    setTimeout(() => {
                        $(`#sharePanels_copy`).html('Copy Link')
                    }, 5000);
                }
            });        

            function openSharePopup ($panel) {
                const url = $panel.data('url');

                // Copy Link
                $('#sharePanels_copy').data('url', url)

                // Image
                $('#sharePanels_image').empty();
                $('.panel-content', $panel).clone().appendTo( '#sharePanels_image' );

                // Social Buttons
                $('#sharePanels_socialButtons').empty()
                $('#sharePanels_socialButtons').append('<a target="_blank" href="mailto:?body=' + url + '" class="link link--email">Email</a>')
                $('#sharePanels_socialButtons').append('<a target="_blank" href="https://www.facebook.com/share.php?u=' + url + '" class="link link--facebook">Facebook</a>')
                $('#sharePanels_socialButtons').append('<a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=' + url + '" class="link link--linkedIn">LinkedIn</a>')
                $('#sharePanels_socialButtons').append('<a target="_blank" href="https://twitter.com/intent/tweet?url=' + url + '" class="link link--twitter">Twitter</a>')
                
                // Show Panel
                $('#sharePanels_popup').addClass('active')
            }
        }



        //---------------------------
        // BLOCK - Brands (#5)
        //---------------------------
        if ($('.blockBrands').length) {

            // horizontal scrolling for brand images
            gsap.utils.toArray('[gsap-carousel]').forEach((item, i) => {
                let tempWidth = 0
                $(item)
                .find('.slider-item')
                .each(function () {
                    tempWidth += $(this).width()
                })

                // calculate if row of brand images need ot be cloned to cover the whole width of the screen
                const innerContent = $(item).html()
                const itemWidth = tempWidth
                let count = Math.ceil(window.innerWidth / itemWidth)
                
                $(item).empty()
                for (let i = 0; i < count + 1; i++) {
                    $(item).append('<div class="slider-clone">' + innerContent + '</div>')
                }

                horizontalLoop(gsap.utils.toArray('.slider-item'), {repeat: -1, speed: 0.4});

                /*
                function startAgain () {
                    this.restart()
                }

                const carouselAnim = gsap.timeline({
                    overwrite: 'auto',
                    onComplete: startAgain
                })

                const speed = 120
                const tickerWidth = itemWidth
                const initDuration = tickerWidth / speed

                carouselAnim.to(item, {
                    duration: initDuration,
                    x:  -itemWidth ,
                    ease: 'none'
                })*/

                // add additional brands clone if window is resized bigger
                window.addEventListener("resize", checkCarouselWidth);
                function checkCarouselWidth() {
                    if (Math.ceil(window.innerWidth / itemWidth) > count) {
                        $(item).append('<div class="slider-clone">' + innerContent + '</div>')
                        count++
                    }
                }
                
            })

        }



        //---------------------------
        // BLOCK - Facts (2x carousels) (#9)
        //---------------------------
        if ($('.imageCarousel').length && $('.contentCarousel').length) {
            
            // Image Carousel
            const imageCarouselElem = $('.imageCarousel')
            const imageOptions = {
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false,
                draggable: false
            }
            let imageCarousel
            imageCarousel = new Flickity(imageCarouselElem[0], imageOptions)

            // Content Carousel
            const inpageCarouselElem = $('.contentCarousel')
            const inpageCountElem = $('.carouselControls-count-total')
            const inpageCurrentElem = $('.carouselControls-count-current')
            const options = {
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false,
                draggable: false,
            }
            let inpageCarousel

            // Add slide length to html
            if (inpageCountElem.length) {
                inpageCountElem.html(
                    inpageCarouselElem.children().length.toString().padStart(2, '0')
                )
            }

            // Flickity create
            inpageCarousel = new Flickity(inpageCarouselElem[0], options)

            // Flickity onchange listener
            inpageCarousel.on('change', function (index) {
                inpageCurrentElem.html((index + 1).toString().padStart(2, '0'))
            })

            // Flickity previous button
            $('.carouselControls-previousBtn').on('click', function () {
                imageCarousel.previous(true)
                inpageCarousel.previous(true)
            })

            // Flickity next button
            $('.carouselControls-nextBtn').on('click', function () {
                imageCarousel.next(true)
                inpageCarousel.next(true)
            })

        }



        //----------------------
        // VIDEO BLOCK (#10)
        //----------------------
        if ($('.blockVideo').length) {
            $('[data-video]').each(function (index, el) {
                const display = $(this).attr('data-video-display')
                const video = this
                let url
                let type

                if ($('video', this).length) {
                    type = 'video'
                    url = $('video', this).attr('data-src')
                } else if ($('iframe', this)) {
                    type = 'iframe'
                    url = $('iframe', this).attr('data-src')
                }

                $('video').on('contextmenu', function (e) {
                    e.preventDefault()
                })

                $('.video-playButton', this).on('click', function (e) {
                    e.preventDefault()
                    //   stopAllVideos()
                    if (display === 'inline') {
                        loadVideo(video)
                    } else if (display === 'popup') {
                        if (type === 'video') {
                        $.magnificPopup.open({
                            items: {
                            type: 'inline',
                            src: '<div class="mfp-inline-video-wrapper"><div class="mfp-inline-video"><button title="Close (Esc)" aria-label="Close" type="button" class="mfp-close">×</button><video class="mfp-inline-video-video" controls="controls" controlsList="nodownload" src="' + url + '" type="video/mp4" autoplay="autoplay"></video></div></div>'
                            }
                        })
                        } else {
                        $.magnificPopup.open({
                            items: {
                            type: 'iframe',
                            src: url
                            }
                        })
                        }
                    }
                })
            })

            // Video lazy loader
            let videoLoadTimeout
            function loadVideo (video) {
                if ($('video', video).length) {
                    $('video', video).attr('src', $('video', video).data('src'))
                }
                if ($('iframe', video).length) {
                    $('iframe', video).attr('src', $('iframe', video).data('src'))
                }
                $('.video-player', video).addClass('video-player--isLoading')
                clearTimeout(videoLoadTimeout)
                videoLoadTimeout = setTimeout(() => {
                    $('.video-player', video).removeClass('video-player--isLoading')
                    $('.video-player', video).addClass('video-player--isLoaded')
                    $('.figure-image', video).addClass('figure-image--isHidden')
                }, 1000)
            }

            const videoBlockTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: "#video-block",
                    scrub: 1,
                    start: "top center",
                    end: "bottom center",
                    // markers: true
                },
            });

            videoBlockTimeline.fromTo(
                "#video-container",
                {   
                    y: 30,
                },
                {
                    y: -30,
                }
            )
        }

    };

    /**
     * Only expose the ready function to the world
     */
    return {
        ready: ready
    }

})($);

// Run main script
$(common.ready);
